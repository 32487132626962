@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";







































































.wizard-product-elem {
  display: flex !important;
  flex-direction: column;
  .svg-elem {}
  .product-button {
    flex-direction: column;
    padding-bottom: m(1);
    min-height: m(10);
    line-height: 1.2;
    white-space: normal;
    .svg-elem {
      width: 80%;
      fill: white(); } }
  .info-button {
    margin-top: 1px;
    &:not(.is-disabled):hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%); } } }
