@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";


































































































































































































































.wizard-page {
  padding-top: m(2);
  .headline {
    .col {
      display: flex;
      align-items: center;
      .button {
        margin-right: m(2); }
      h2 {
        @include font('regular');
        .prefix {
          padding-right: m(2);
          @include font('bold'); } } } }
  .options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: m(102);
    margin: m(2) auto; }
  .progress-bar {
    margin-top: m(4);
    .col {
      border-top: 2px solid grey(6);
      padding-top: m(3);
      text-align: center;
      .bar {
        display: inline-block;
        text-align: left;
        width: 40%;
        height: m(2);
        line-height: 0;
        background-color: grey(7);
        border-radius: $radius-rounded;
        .fill {
          display: inline-block;
          width: 0;
          height: m(2);
          background-color: $primary-color;
          border-top-left-radius: $radius-rounded;
          border-bottom-left-radius: $radius-rounded;
          transition: width .5s ease; } } } }
  .product-select {
    margin-top: m(3); }
  .result-product {
    margin-top: m(6);
    justify-content: center; }
  .result-text {
    margin-top: m(3);
    .col {
      text-align: center;
      * {
        @include font('tool'); } } }
  .help {
    text-align: center;
    @include font('semibold', 'tool'); } }

@include sm {
  .wizard-page {
    .options {
      width: auto;
      margin: m(2) 0; }
    .progress-bar {
      .col {
        .bar {
          width: 90%; } } }
    .product-select {
      .col {
        .product-button {
          height: m(10); } } } } }

@include xs {
  .wizard-page {
    .options {
      display: block; }
    .progress-bar {
      .col {
        .bar {
          width: 100%; } } }
    .product-select {
      .col {
        .product-button {
          padding-top: m(2);
          padding-bottom: m(1);
          height: auto; }
        .info-button {
          padding-top: m(1);
          padding-bottom: m(1); } } } } }
